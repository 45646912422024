<template>
    <div class="max_cont_width flex_box" @mousedown="handleonmousedown($event)">
        <div class="left_menu">
            <button class="btn_Retr" @click="RetrievalResult">
                <img src="../../assets/img/search/btn_ic1.png" />
                Apply Filters
            </button>
            <el-collapse v-model="openMenu" v-loading="loadig" @change="zhankai()">
                <el-collapse-item title="Country" name="1">
                    <template slot="title">
                        Country
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="cas_loc">
                        <el-cascader ref="Country" placeholder="Select Country" clearable :filter-method="filterCasCountry" filterable v-model="countryCheckList" @change="menuCheck('country')" :options="countryList" collapse-tags popper-class="cas_loc_box">
                            <template slot-scope="{ data }">
                                <!-- <span class="casca_lab">{{ data.label }}</span> -->
                                <el-tooltip class="casca_lab" effect="dark" :content="data.label" placement="top-start">
                                    <!-- <div  @mouseover="onMouseOver(child.name,i)" class="line_1 coll_child_name" ><b ref="str">{{ child.name }}</b></div> -->
                                    <span class="casca_lab">
                                        <span ref="sty">{{ data.label }}</span>
                                    </span>
                                </el-tooltip>
                                <!-- <span class="casca_count">({{ data.count }})</span> -->
                                <el-tooltip class="casca_count" effect="dark" :content="data.count | FilterNum" placement="top-start">
                                    <span class="casca_count">{{ data.count | FilterNum }}</span>
                                </el-tooltip>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Industry" name="2">
                    <template slot="title">
                        Industry
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="cas_loc">
                        <el-cascader v-model="industryCheckList" clearable :filter-method="filterCasindst" filterable @change="menuCheck('Industry')" :options="industryList" placeholder="Select Industry" :props="optionsProps" popper-class="cas_loc_box indystry">
                            <template slot-scope="{ data }">
                                <el-tooltip class="" effect="dark" :content="data.valueEn" placement="top-start">
                                    <span class="casca_lab">{{ data.valueEn }}</span>
                                </el-tooltip>
                                <span class="line_1 coll_child_num2">{{ data.dateCount | FilterNum }}</span>
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company" name="6">
                    <template slot="title">
                        Company
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="cas_panel cas_loc">
                        <!-- <input type="text" v-model="cascInptIndustry" v-show="showInptIndustry" class="cas_inpt" placeholder="Select Company" @input="inptIndSearch" /> -->
                        <el-cascader clearable filterable :filter-method="filterCasindst" v-model="companynameCheckList" :options="companynameList" @change="menuCheck('')" placeholder="Select Company" collapse-tags :props="optionsProps" popper-class="cas_loc_box ">
                            <template slot-scope="{ data }">
                                <el-tooltip class="casca_lab" effect="dark" :content="data.valueEn" placement="top-start">
                                    <span class="casca_lab">{{ data.valueEn }}</span>
                                </el-tooltip>
                                <!-- <el-tooltip class="casca_count" effect="dark" :content="data.dateCount | FilterNum " placement="top-start">
                                 <span class="casca_count">{{ data.dateCount | FilterNum  }}</span>
                                </el-tooltip> -->
                            </template>
                        </el-cascader>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Status" name="3">
                    <template slot="title">
                        Company Status
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companyStatusCheckList" @change="menuCheck('companyStatus')">
                            <el-checkbox v-for="(child, i) in companyStatusList" :key="i" :label="child.valueEn">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>

                                <el-tooltip class="line_1 coll_child_num" effect="dark" :content="child.dateCount | FilterNum" placement="top-start">
                                    <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Company Size" name="4">
                    <template slot="title">
                        Company Size
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="companySizeCheckList" @change="menuCheck('companySize')">
                            <el-checkbox v-for="(child, i) in companySizeList" :key="i" :label="child.valueEn">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>
                                <!-- <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span> -->
                                <el-tooltip class="line_1 coll_child_num" effect="dark" :content="child.dateCount | FilterNum" placement="top-start">
                                    <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="Year in Business" name="5">
                    <template slot="title">
                        Year in Business
                        <i class="header-icon el-icon-lock hedr-icon-ydong" v-show="!powerResult[0]?.list[1]?.power"></i>
                    </template>
                    <div class="coll_child">
                        <el-checkbox-group v-model="timePeriodCheckList" @change="menuCheck('timePeriod')">
                            <el-checkbox v-for="(child, i) in timePeriodList" :key="i" :label="child.valueEn">
                                <span class="line_1 coll_child_name">{{ child.valueEn }}</span>
                                <el-tooltip class="line_1 coll_child_num" effect="dark" :content="child.dateCount | FilterNum" placement="top-start">
                                    <span class="line_1 coll_child_num">{{ child.dateCount | FilterNum }}</span>
                                </el-tooltip>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="right_box">
            <div class="top_condition flex_center_between_box">
                <div class="condition_lab_box">
                    <div :class="{ condition_labb: true, condition_lab: isActive }" @click="tabshow">
                        <template>
                            <el-tag v-for="tag in countryTag" :key="tag" @close="handleClose(tag, 'countryTag')" closable>{{ tag }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in industryTag" :key="tag.code + '_industryTag'" @close="handleClose(tag, 'industryTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companyStatusTag" :key="tag.code + '_companyStatusTag'" @close="handleClose(tag, 'companyStatusTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companySizeTag" :key="tag.code + '_companySizeTag'" @close="handleClose(tag, 'companySizeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in timeTag" :key="tag.code + '_timeTag'" @close="handleClose(tag, 'timeTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <template>
                            <el-tag v-for="tag in companynameTag" :key="tag.code + '_companynameTag'" @close="handleClose(tag, 'companynameTag')" closable>{{ tag.valueEn }}</el-tag>
                        </template>
                        <span class="clear_lab" @click="clearAllTags">Clear All</span>
                    </div>
                </div>

                <div class="flex_center">
                    <div class="top_ic_btn no_login" @click="showTranslate = !showTranslate">
                        <img src="../../assets/img/detail/head_ic1.png" class="no_login" />
                    </div>
                    <div class="btn_export flex_center" @click="exportResult">
                        <img src="../../assets/img/search/export_ic1.png" />
                        Export
                    </div>
                </div>
            </div>
            <div class="right_cont_box">
                <div class="table_box">
                    <el-table ref="treeTable" :key="refresh" :data="parentTable" border :row-class-name="getRowClass" row-key="pid" :expand-row-keys="expands" style="width: 100%" v-loading="loading">
                        <el-table-column type="expand" align="center" width="1">
                            <template slot-scope="scope">
                                <div class="tree_table_box">
                                    <div class="tbl_search_box" v-if="isNameSearch">
                                        <div class="tbl_search">
                                            <el-input clearable v-model="tblSearchName" type="text" @change="searchCompanyName()" placeholder="Please enter name" @blur="seach" />
                                            <img src="../../assets/img/detail/search_ic1.png" @click="searchCompanyName()" />
                                        </div>
                                    </div>
                                    <el-table :data="childTableList1" border :key="refresh">
                                        <!-- :key="scope.row.aaaId" -->
                                        <template slot="empty">No Data</template>
                                        <el-table-column label="Company Name" align="left" show-overflow-tooltip :resizable="false" class-name="company company_link">
                                            <template slot="header">
                                                <div class="table_add" @click="showSearchCompany">
                                                    Company Name
                                                    <img src="../../assets/img/detail/search_ic1.png" />
                                                </div>
                                            </template>
                                            <template slot-scope="scope">
                                                <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                                <div @click="setid(scope.row)" class="tbl_company line_1" v-show="showTranslate">{{ scope.row.companyName }}</div>
                                                <div @click="setid(scope.row)" class="tbl_company line_1" v-show="!showTranslate">{{ scope.row.companyNameEn }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Location" width="120" align="center" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <div class="line_1" style="width: 100px" v-show="!showTranslate">
                                                    {{ scope.row.cityEn }}
                                                    <i v-if="scope.row.provinceEn != ' ' && scope.row.provinceEn != '' && scope.row.cityEn != '' && scope.row.cityEn != ' '">,</i>
                                                    {{ scope.row.provinceEn }}
                                                </div>
                                                <div class="line_1" style="width: 100px" v-show="showTranslate">
                                                    {{ scope.row.city }}
                                                    <i v-if="scope.row.province != ' ' && scope.row.province != '' && scope.row.city != '' && scope.row.city != ' '">,</i>
                                                    {{ scope.row.province }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Industry" prop="industryEn" align="center" show-overflow-tooltip :resizable="false">
                                        </el-table-column>
                                        <el-table-column label="Inc.Yr." prop="establishmentYear" width="100" align="center" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Status" prop="companyStatusEn" width="100" align="center" show-overflow-tooltip :resizable="false">
                                        </el-table-column>
                                        <el-table-column label="Type" prop="companyTypeEn" width="140" align="center" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <div class="line_1" style="width: 120px">{{ scope.row.companyTypeEn }}</div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Size" prop="companySizeEn" width="100" align="center" show-overflow-tooltip :resizable="false">
                                        </el-table-column>
                                        <el-table-column label="" prop="operation" align="center" width="50" :resizable="false">
                                            <template slot-scope="scope">
                                                <div class="table_oper on" style="position: relative; left: -10px" v-if="scope.row.isFavorite" @click="addToList(scope.row), (fromPage = 'company')">
                                                    <img src="../../assets/img/search/table_ic1.png" />
                                                </div>
                                                <div class="table_oper" v-else style="position: relative; left: -10px" @click="addToList(scope.row), (fromPage = 'company')">
                                                    <img src="../../assets/img/search/table_ic2.png" />
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <div class="page_i_box">
                                        <Page :totalFont="true" :total="pageChild.total" @onPageChange="onPageChangeList" @click="aaaa(scope.row)" @currtentPageChange="currtentPageChange1"></Page>
                                        <div class="search_rsult_txt line_2">{{ pageChild.pageNo }}-{{ pageChild.pageSize }} of over {{ pageChild.total | FilterNum }} results</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Name of a person" prop="pname,pnameEn" align="left" width="350" show-overflow-tooltip :resizable="false" class-name="company_link">
                            <template slot-scope="scope">
                                <div class="btn_expand" :class="{ expand_icon: scope.row.isExpand }" @click="onClickExpansion(scope.row)"></div>
                                <div @click="setid1(scope.row)" style="width: 330px" exact class="tbl_company" v-show="showTranslate">{{ scope.row.pname }}</div>
                                <div @click="setid1(scope.row)" exact class="tbl_company" v-show="!showTranslate">{{ scope.row.pnameEn }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Age" prop="age" width="120px" align="center" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Gender" prop="gender" width="120px" align="center" show-overflow-tooltip :resizable="false" />
                        <el-table-column label="Country" prop="countryEn" width="135px" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <el-tooltip style="width: 115px" class="line_1" placement="top">
                                    <div slot="content" v-for="(item, index) in scope.row.countryCode.split(';')" :key="index" @click="gotocountry2(item)" style="margin-bottom: 6px; margin-top: 6px; cursor: pointer">{{ getCountry(item) }}</div>
                                    <div style="width: 115px" @click="gotocountry1(scope.row)" exact class="line_1 tbl_company">{{ scope.row.countryEn }}</div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="Roles" prop="roleNum" width="120" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <div style="width: 100px">{{ scope.row.roleNum | FilterNum }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Relations" width="120" prop="relationNum" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <span style="width: 100px">{{ scope.row.relationNum | FilterNum }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Partner" prop="partnerNum" width="120" align="center" show-overflow-tooltip :resizable="false">
                            <template slot-scope="scope">
                                <span style="width: 100px">{{ scope.row.partnerNum | FilterNum }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="" align="center" width="60" :resizable="false">
                            <template slot-scope="scope">
                                <div class="table_oper on" v-if="scope.row.isFavorite" @click="addPersonToList(scope.row), (fromPage = 'person')">
                                    <img src="../../assets/img/search/table_ic1.png" />
                                </div>
                                <div class="table_oper" v-else @click="addPersonToList(scope.row), (fromPage = 'person')">
                                    <img src="../../assets/img/search/table_ic2.png" />
                                </div>
                            </template>
                        </el-table-column>

                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <noDate />
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                        <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results for "{{ searchName }}"</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 收藏弹框 -->
        <Collection :fromPage="fromPage" @childCloseDialog="closeDialog1(arguments)" :addList.sync="addList" :addPop="addPopIndex" :collecObj="collecObj"></Collection>

        <!-- 点击导入 -->
        <el-dialog :visible.sync="exportDialog" center :close-on-click-modal="false" custom-class="large_dialog">
            <div class="large_detail_cont">
                <div class="dialog_detail_title">Please select a person name</div>
                <div class="company_box">
                    <div class="comp_top_check flex_center_between_box">
                        <el-checkbox v-model="checkAll" @change="checkedAllCompany">Select All</el-checkbox>
                        <div class="comp_search">
                            <input class="coll_search_inpt" v-model="filterName" type="text" placeholder="Enter person name" @keyup.enter="hanldFilterName()" />
                            <img class="coll_search_btn" src="../../assets/img/head_search_ic.png" @click="hanldFilterName" />
                        </div>
                    </div>
                    <div class="check_item" v-infinite-scroll="infiniteScroll" :infinite-scroll-disabled="routeLoad || noMore" :infinite-scroll-distance="5">
                        <el-checkbox-group v-model="checkedCompanyList">
                            <el-checkbox v-for="(company, index) in companyList" :label="company" :key="index">{{ company.pnameEn }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="company_b_txt">{{ checkedCompanyList.length }} enterprises were selected</div>
                <div class="btn_comp_box">
                    <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="company.xls">
                        <button class="btn_export" @click="exportCompany">Export</button>
                    </download-excel>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { filterConditions, psersonSearch, selectGroup } from '@/api/searchApi.js';
import { getCompanyDetailMenuList, ordersAdd, getIsCollect } from '../../api/companyApi';
import Collection from '@/components/collection';
import Page from '@/components/page';
import noDate from '@/components/tableNoDate';
import { mixins1 } from '@/mixins/index';
import bus from '@/components/bus';
export default {
    mixins: [mixins1],
    components: { noDate, Page, Collection },
    data() {
        return {
            loading: true,
            loadig: true,
            isActive: false,
            refresh: 0,
            tblSearchName: '',
            searchName: '',
            isNameSearch: false,
            //openMenu:['Country','Location'],
            showTranslate: false,
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            pid: '',
            expands: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            filterName: '',
            filterCountry: '',
            //表
            parentTable: [],
            childTable: [],
            childTableList: [],
            addList: [],
            addPopIndex: false,
            fromPage: 'person',
            collecObj: {},
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            companyList: [],
            companyTblList: [],

            indusOptions: [],
            openMenu: [],
            countryList: [],
            countryCheckList: [],
            countryTag: [],
            countryCheck: '',
            tblCountryList: [],

            industryCheckList: [],
            industryList: [],
            industryTag: [],
            industryCheck: '',

            companyStatusList: [],
            companyStatusCheckList: [],
            companyStatusTag: [],
            companyStatusCheck: '',
            childTableList1: [],
            companySizeList: [],
            companySizeCheckList: [],
            companySizeTag: [],
            companySizeCheck: '',

            timePeriodList: [],
            timePeriodCheckList: [],
            timeTag: [],
            timePeriodCheck: '',
            companynameList: [],
            companynameCheck: '',
            companynameCheckList: [],
            companynameTag: [],
            token: localStorage.getItem('token'),
            powerResult: [],
            personRow: {},
            // 下载
            title: 'person',
            optionsProps: {
                label: 'valueEn', //名称展示
                value: 'code', //值展示
                dateCount: 'dateCount',
                expandTrigger: 'click', //次级菜单的展开方式
            },
            json_fields: {
                'Name of a person': 'pnameEn',
                Age: 'age',
                Gender: 'gender',
                Country: 'countryCode',
                Roles: 'roleNum',
                Relations: 'relationNum',
                Partner: 'partnerNum',
            },
            DetailsForm: [],
            pagecount: 10,
        };
    },
    mounted() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.openMenu = ['1', '2', '3', '4', '5','6'];
                    this.getPower();
                    this.getConditions();
                }
                // this.loadig=false
            },
            immediate: true,
        },
    },
    created() {
        this.getPower();
        this.getConditions();
    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        zhankai() {
            let hasPower = this.powerResult[0].list[1].power;
            if (!hasPower) {
                this.openMenu = [];
                this.message();
            }
        },
        // 跳转国家
        gotocountry(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row.countryCode,
            });

            window.open(routeData.href, '_blank');
        },
        // 多个国家跳转
        gotocountry2(row) {
            let routeData = this.$router.resolve({
                path: '/country?' + '&companyCountry=' + row,
            });

            window.open(routeData.href, '_blank');
        },
        gotocountry1(row) {
            if (!row.countryCode.includes(';')) {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
        },
        pageList() {
            this.childTableList1 = this.childTable.filter((item, index) => index < this.pageChild.pageNo * this.pageChild.pageSize && index >= this.pageChild.pageSize * (this.pageChild.pageNo - 1));

            this.pageChild.total = this.childTable.length;
        },

        currtentPageChange(pageSize) {
            let hasPower = this.powerResult[0].list[5].power;
            if (hasPower) {
                this.page.pageSize = pageSize;
                this.getPerson();
            } else {
                this.message();
            }
        },

        onPageChangeList(pageNo) {
            this.pageList();
            let hasPower = this.powerResult[0].list[5].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.pageChild.pageNo = pageNo;
                // this.init()
                this.pageList();
            } else {
                this.message();
            }
        },
        currtentPageChange1(pageSize) {
            let hasPower = this.powerResult[0].list[5].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.pageChild.pageSize = pageSize;
                // this.init()
                this.pageList();
            } else {
                this.message();
            }
            // this.pageNo += 1
        },
        tabshow() {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        // 处理鼠标点击事件 登录权限
        handleonmousedown(e) {
            // 处理登录功能：
            // 未登录  允许点击【翻译】和【详情】
            const token = localStorage.getItem('token');
            if (!token) {
                // || e.target._prevClass === 'tbl_company'
                if (!(e.target?._prevClass === 'no_login')) {
                    bus.$emit('handleShowLogin', true); //是否显示登录
                    // bus.$emit('headLogFlg', true); //是否显示登录
                }
            }
        },
        // 获取人员数据
        getPerson() {
            try {
                this.loading = true;
                this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
                this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;

                let para = 'page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&name=' + this.searchName + '&countryCode=' + this.searchCode + '&companySizeList=' + this.companySizeCheck + '&establishmentYearList=' + this.timePeriodCheck + '&companyStatusCodeList=' + this.companyStatusCheck + '&industryCodeList=' + this.industryCheck + '&companyName=' + this.companynameCheck;

                psersonSearch(para).then(
                    res => {
                        if (res && res.code && res.data) {
                            this.loading = false;
                            console.log('result- psersonSearch', res.data);
                            this.page.total = Number(res.data.total);
                            const list = (this.parentTable = res.data.rows);
                            let array = [];
                            list.forEach(item => {
                                if (item.companyInfoList.length > 0) {
                                    const arr = item.companyInfoList;
                                    //查找本地图片
                                    array = arr.map(it => {
                                        let countryImg = this.getCountryImgUrl(it.countryCode);
                                        let flag = require('../../../static' + countryImg);
                                        it.flag = flag;
                                    });
                                }
                            });
                        } else {
                            this.loading = false;
                            this.parentTable = [];
                        }
                    },
                    reject => {
                        this.loadig = false;
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
        //获取菜单
        async getConditions() {
            this.loadig = true;
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            this.industryCheck = this.industryCheck == undefined ? '' : this.industryCheck;
            let para = 'page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&name=' + this.searchName + '&countryCode=' + this.searchCode + '&companySizeList=' + this.companySizeCheck + '&establishmentYearList=' + this.timePeriodCheck + '&companyStatusCodeList=' + this.companyStatusCheck + '&industryCodeList=' + this.industryCheck + '&companyName=' + this.companynameCheck;

            await filterConditions(para)
                .then(
                    res => {
                        if (res && res.code && res.data) {
                            const data = res.data;
                            this.loadig = false;
                            this.countryList = data.countryList;
                            this.industryList = data.industryList;
                            //     this.industryList=[
                            //     {
                            //     "code": "66",
                            //     "dateCount": null,
                            //     "value": "金融保险服务及其附属活动",
                            //     "valueEn": "Activities auxiliary to financial service and insurance activities",
                            //     "pid": null,
                            //     "children": [
                            //         {
                            //             "code": "0019",
                            //             "dateCount": 3,
                            //             "value": "其他金融服务附属活动",
                            //             "valueEn": "Other activities auxiliary to financial service activities",
                            //             "pid": "66",
                            //             "children": null
                            //         }
                            //     ]
                            // },       {
                            //     "code": "22",
                            //     "dateCount": null,
                            //     "value": "橡胶和塑料制品的制造",
                            //     "valueEn": "Manufacture of rubber and plastics products",
                            //     "pid": null,
                            //     "children": [
                            //         {
                            //             "code": "2220",
                            //             "dateCount": 3,
                            //             "value": "塑料制品的制造",
                            //             "valueEn": "Manufacture of plastics products",
                            //             "pid": "22",
                            //             "children": null
                            //         }
                            //     ]
                            // },
                            //     ]
                            this.companyStatusList = data.companyStatusList;
                            this.companySizeList = data.companySizeList;
                            this.timePeriodList = data.establishmentYearList;
                            this.companynameList = data.companyNameList;


                            let list2 = this.countryList;
                            if (list2.length > 0) {
                                list2.map((item, index) => {
                                    item.label = item.valueEn;
                                    item.value = item.code;
                                    item.count = item.dateCount;
                                    if (item.companyProvince) {
                                        item.children = item.companyProvince;
                                        item.children.map(it => {
                                            it.label = it.valueEn;
                                            it.value = it.code;
                                            it.count = it.dateCount;
                                        });
                                    }
                                });
                            }
                        } else {
                            this.loadig = false;
                        }
                    },
                    reject => {
                        this.loadig = false;
                    }
                )
                .catch(res => {
                    this.loadig = false;
                });
        },
        //搜索人员子公司
        searchCompanyName() {
            let list = this.childTableList;
            let keyWord = this.tblSearchName;
            var arr = [];
            if (keyWord == '') {
                this.childTable = list;
            } else {
                arr = list.filter(item => {
                    return item.companyNameEn.toLowerCase().includes(keyWord.toLowerCase()) || item.companyName.includes(keyWord.toLowerCase());
                });
                this.childTable = arr;
            }
            this.pageList();
            this.refresh++;
        },

        //权限
        async getPower() {
            if (this.token) {
                this.loadig = true;
            } else {
                this.loadig = false;
            }
            this.getPowerMenu().then(async () => {
                let hasPower = this.powerResult[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    this.getPerson();
                    this.getCollData();
                }
            });
        },
        //权限
        async getPowerMenu() {
            let params = 'type=4' + '&code=' + this.pid;
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                this.powerResult = res.data;
            }
        },
        /**
         *用户购买报告 "type": 18 ：PersonsearchResults展开（子集）
         */
        getOrdersAdd() {
            const type = 18;
            let params = {
                companyName: this.personRow.pnameEn, //人员名称
                aaaId: this.personRow.pid, //人员pid
                companyCountry: this.personRow.countryCode, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        this.getPowerMenu();
                        resolve(true);
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            duration: 5000,
                        });
                        resolve(false);
                    }
                });
            });
        },

        //模糊查询
        seach() {
            if (this.tblSearchName == '') {
                // console.log(this.tblSearchName);
                this.isNameSearch = false;
            }
        },
        hanldFilterName() {
            let list = this.companyTblList;
            let keyWord = this.filterName;
            var arr = [];
            if (keyWord == '') {
                return (this.companyList = list);
            } else {
                arr = list.filter(item => {
                    return item.pnameEn.includes(keyWord);
                });
                this.companyList = arr;
            }
        },

        //国家级联搜索不区分大小写
        filterCasCountry(node, val) {
      
            if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
                return true;
            }
        },
        filterCasindst(node, val) {
     
            if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
                return true;
            }
        },
        //二次查询
        async RetrievalResult() {
            let hasPower = this.powerResult[0].list[1].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                // this.getConditions();
                this.getPerson();
            } else {
                this.message();
            }
        },

        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));
            localStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode + '&information=Search Person',
            });
            window.open(routeData.href, '_blank');
        },
        setid1(row) {
            this.getPowerPersonDetail(row);
        },
        //PersonDetail 是否能链接进去 权限
        async getPowerPersonDetail(row) {
            let params = 'type=8' + '&code=' + row.pid;
            let res = await getCompanyDetailMenuList(params);
            if (res && res.code) {
                console.log(`res.data`, res.data);
                let hasPower = res.data[0].power;
                // || (!hasPower && (await this.getOrdersAddPerson(row)))
                if (hasPower) {
                    sessionStorage.setItem('User', JSON.stringify(row));
                    localStorage.setItem('User', JSON.stringify(row));
                    let routeData = this.$router.resolve({
                        path: '/users/background?pid=' + row.pid + '&name=' + row.pnameEn + '&companyCountry=' + row.countryCode + '&information=Search Person',
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.message();
                }
            }
        },
        /**
         *用户购买报告 "type": 14 ：personDetail
         */
        getOrdersAddPerson(row) {
            const type = 14;
            let params = {
                companyName: row.pnameEn, //人员名称
                aaaId: row.pid, //人员pid
                companyCountry: row.countryCode == '' ? 'CN' : row.countryCode, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                        resolve(true);
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        setTimeout(function () {
                            let routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });

                            window.open(routeData.href, '_blank');
                        }, 2000);
                        resolve(false);
                    }
                });
            });
        },

        // 判断表格是否有子项，无子项不显示展开按钮
        getRowClass(row, rowIndex) {
            if (row.row.companyInfoList.length === 0) {
                return 'row-expand-cover';
            }
        },
        //菜单checkbox选择
        menuCheck(val) {

            if (val === 'country') {
                let arrCountry = [];
                let arrProv = [];
                let arrCountryTag = [];
                this.countryCheckList.forEach(item => {
                    if (!arrCountry.includes(item)) {
                        this.countryList.forEach(ite => {
                            if (ite.value == item) {
                                arrCountryTag.push(ite.label);
                            }
                        });

                        arrCountry.push(item);
                    }
                    // arrProv.push(item[1]);
                });
                // this.countryCheck = arrCountry.join('@@');
                // this.province = arrProv.join('@@');
                this.countryTag = arrCountryTag;
                this.countryCheck = arrCountry;
                this.province = arrProv;
            }
            if (val === 'Industry') {
                let arr = [];
                this.industryCheckList.forEach(item => {
                    this.industryList.forEach(it => {
                        if (it.code == item) {
                            arr.push(it);
                        }
                    });
                });
                let arrlist = [];
                arr.map(item => {
                    arrlist.push(item.code);
                });
                this.industryTag = arr;
                // this.industryCheck = this.industryCheckList.join('@@');
                // this.industryCheck = arrlist;
                // 多级
                this.industryCheck = this.industryCheckList[1] == undefined ? this.industryCheckList[0] : this.industryCheckList[1];
            }
            // if (val === 'companySize') {
            let arr1 = [];
            this.companySizeCheckList.forEach(item => {
                this.companySizeList.forEach(it => {
                    if (it.valueEn == item) {
                        arr1.push(it);
                    }
                });
            });
            // this.companySizeTag = arr1;
            let arrlist1 = [];
            arr1.map(item => {
                arrlist1.push(item.code);
            });
            this.companySizeTag = arr1;
            // this.companySizeCheck = this.companySizeCheckList.join('@@');
            // this.companySizeCheck = this.companySizeCheckList;
            this.companySizeCheck = arrlist1;
            // }
            // if (val === 'companyStatus') {
            let arr2 = [];
            this.companyStatusCheckList.forEach(item => {
                this.companyStatusList.forEach(it => {
                    if (it.valueEn == item) {
                        arr2.push(it);
                    }
                });
            });
            let arrlist2 = [];
            arr2.map(item => {
                arrlist2.push(item.code);
            });
            this.companyStatusTag = arr2;
            this.companyStatusCheck = arrlist2;

            let arr3 = [];
            this.timePeriodCheckList.forEach(item => {
                this.timePeriodList.forEach(it => {
                    if (it.valueEn == item) {
                        arr3.push(it);
                    }
                });
            });

            let arrlist3 = [];
            arr3.map(item => {
                arrlist3.push(item.code);
            });

            this.timeTag = arr3;
            this.timePeriodCheck = arrlist3;
            let arr4 = [];
            this.companynameCheckList.forEach(item => {
                this.companynameList.forEach(it => {
                    if (it.code == item) {
                        arr4.push(it);
                    }
                });
            });
            let arrlist4 = [];
            arr4.map(item => {
                arrlist4.push(item.value);
            });
            this.companynameTag = arr4;
            this.companynameCheck = arrlist4.join('@@');
            this.getConditions();
        },
        //清除标签
        handleClose(tag, name) {

            if (name === 'countryTag') {
                this.countryTag.splice(this.countryTag.indexOf(tag), 1);

                let tagcountry = '';
                this.countryCheckList.forEach((item, index) => {
                    this.countryList.forEach(ite => {
                        if (ite.label == tag) {
                            tagcountry = ite.code;
                        }
                    });
                    if (item.includes(tagcountry)) {

                        this.countryCheckList.splice(index, 1);
                        this.countryCheck.splice(index, 1);
                    }
                });

                if (this.countryTag.length == 0) {
                    this.searchCode = '';
                    this.countryCheck = '';
                }
                // this.countryCheckList.splice(this.countryCheckList.indexOf(tag), 1);
            }
            if (name === 'marketTag') {
                this.marketTag.splice(this.marketTag.indexOf(tag), 1);
                this.marketCheckList.splice(this.marketCheckList.indexOf(tag.code), 1);
            }
            if (name === 'industryTag') {
                this.industryTag.splice(this.industryTag.indexOf(tag), 1);
                this.industryCheckList.splice(this.industryCheckList.indexOf(tag.code), 1);
                this.industryCheckList = [];
                this.industryCheck = '';

            }
            if (name === 'companySizeTag') {
                this.companySizeTag.splice(this.companySizeTag.indexOf(tag), 1);
                this.companySizeCheckList.splice(this.companySizeCheckList.indexOf(tag.code), 1);
                // this.companySizeCheck = this.companySizeCheckList;
                let arr1 = [];
                this.companySizeTag.map(item => {
                    arr1.push(item.code);
                });
                this.companySizeCheck = arr1;
            }

            if (name === 'companyStatusTag') {
                this.companyStatusTag.splice(this.companyStatusTag.indexOf(tag), 1);
                this.companyStatusCheckList.splice(this.companyStatusCheckList.indexOf(tag.code), 1);
                // this.companyStatusCheck = this.companyStatusCheckList;
                let arr3 = [];
                this.companyStatusTag.map(item => {
                    arr3.push(item.code);
                });
                this.companyStatusCheck = arr3;
            }
            if (name === 'companynameTag') {
                this.timeTag.splice(this.timeTag.indexOf(tag), 1);
                this.timePeriodCheckList.splice(this.timePeriodCheckList.indexOf(tag.code), 1);
                let arr4 = [];
                this.timeTag.map(item => {
                    arr4.push(item.code);
                });
                this.timePeriodCheck = arr4;
            }
            if (name === 'companynameTag') {
                this.companynameTag.splice(this.companynameTag.indexOf(tag), 1);
                this.companynameCheckList.splice(this.companynameCheckList.indexOf(tag.code), 1);
                // this.companySizeCheck = this.companySizeCheckList;
                let arr1 = [];
                this.companynameTag.map(item => {
                    arr1.push(item.code);
                });
                this.companynameCheck = arr1;
            }

            this.getConditions();
        },
        //清除所有标签
        clearAllTags() {
            // this.countryTag = [];
            this.companynameList = [];
            (this.companynameCheck = ''), (this.companynameCheckList = []), (this.companynameTag = []), (this.industryTag = []);
            this.companySizeTag = [];
            this.companyStatusTag = [];
            this.timeTag = [];
            this.countryTag = [];
            this.countryCheckList = [];
            this.industryCheckList = [];
            this.companySizeCheckList = [];
            this.companyStatusCheckList = [];
            this.timePeriodCheckList = [];

            this.countryCheck = [];
            this.industryCheck = [];
            this.companySizeCheck = [];
            this.companyStatusCheck = [];
            this.timePeriodCheck = [];

            this.getConditions();
        },
        //分页
        async onPageChange(pageNo) {
            let hasPower = this.powerResult[0].list[5].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.page.pageNo = pageNo;
                this.getPerson();
            } else {
                this.message();
            }
        },

        //添加列表
        async addPersonToList(row) {
            let hasPower = this.powerResult[0].list[4].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                let collIds = [];
                let params = {
                    crefoNo: row.pid,
                };
                await getIsCollect(params).then(res => {
                    if (res.code) {
                        collIds = res.data;
                    }
                });
                this.collecObj = row;
                this.collecObj.collectionIdList = collIds;
                this.addPopIndex = true;
            } else {
                this.message();
            }
        },
        // 
        async addToList(row) {
            let hasPower = this.powerResult[0].list[4].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                let collIds = [];
                let params = {
                    crefoNo: row.aaaId,
                };
                await getIsCollect(params).then(res => {
                    if (res.code) {
                        collIds = res.data;
                    }
                });
                this.collecObj = row;

                this.collecObj.company_name_en = row.companyNameEn;
                this.collecObj.aaa_id = row.aaaId;
                this.collecObj.company_country = row.countryCode;
                this.collecObj.collectionIdList = collIds;

                this.addPopIndex = true;
            } else {
                this.message();
            }
        },
        // 获取收藏
        async getCollData() {
            await selectGroup().then(res => {
                if (res && res.code) {
                    this.addList = res.data.rows;
                }
            });
        },
        // 收藏弹框
        closeDialog1(value) {
            this.addPopIndex = value[0];
            this.collecObj.isFavorite = value[1];
        },
        //导出 title: "export"
        async exportCompany() {
            let hasPower = this.powerResult[0].list[3].power;
            if (hasPower || (!hasPower && (await this.getOrdersAdd()))) {
                this.DetailsForm = this.checkedCompanyList;
            }
        },
        //导出弹框
        async exportResult() {
            this.companyList = [];

            let hasPower = this.powerResult[0].list[3].power;
            // || (!hasPower && (await this.getOrdersAdd()))
            if (hasPower) {
                this.pageNo = 1;
                this.pageSize = 20;
                this.getRouteList();
                this.exportDialog = true;
            } else {
                this.message();
            }
        },
        // 滚动加载方法
        infiniteScroll() {
            this.routeLoad = true;
            this.pageNo += 1; // 页码每次滚动+1
            this.getRouteList();
        },
        // 获取数据
        async getRouteList() {
            this.searchName = this.$route.query.searchname ? this.$route.query.searchname : '';
            this.searchCode = this.countryCheck ? this.countryCheck : this.$route.query.companyCountry;
            this.industryCheck = this.industryCheck == undefined ? '' : this.industryCheck;
            let para = 'page=' + this.pageNo + '&pageSize=' + this.page.pageSize + '&name=' + this.searchName + '&countryCode=' + this.searchCode + '&companySizeList=' + this.companySizeCheck + '&establishmentYearList=' + this.timePeriodCheck + '&companyStatusCodeList=' + this.companyStatusCheck + '&industryCodeList=' + this.industryCheck + '&companyName=' + this.companynameCheck;

            await psersonSearch(para)
                .then(res => {
                    let list = res.data.rows;
                    for (let i = 0; i < list.length; i++) {
                        this.companyList.push(list[i]);
                    }
                    this.companyTblList = this.companyList;
                    if (list.length < 10) {
                        this.noMore = true;
                    }
                    if (this.companyList.length === res.data.total) {
                        this.noMore = true;
                    }
                    this.routeLoad = false;
                })
                .catch(res => {});
        },

        //弹窗全选公司
        checkedAllCompany(val) {
            this.checkedCompanyList = val ? this.companyList : [];
        },
        //展开当前关闭其他展开表格
        async onClickExpansion(row) {
            this.personRow = row;
            this.pid = row.pid;
            this.getPowerMenu().then(async () => {
                let hasPower = this.powerResult[0].list[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    if (this.expands.indexOf(row.pid) < 0) {
                        this.expands = [];
                        this.expands.push(row.pid);
                        this.childTable = row.companyInfoList;
                        this.childTableList = row.companyInfoList;
                        this.pageChild.pageNo = 1;
                        this.pageList();
                    } else {
                        this.expands = [];
                    }
                } else {
                    this.message();
                }
            });
        },

        //table 搜索
        showSearchCompany() {
            this.isNameSearch = true;
            this.refresh++;
        },
    },
};
</script>
<style scoped>
.add_list {
    margin-bottom: 20px;
}
.add_item {
    display: flex;
    padding: 10px 9px 8px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
}
.add_item:hover {
    background: #e7eaed;
}
.add_item.on {
    background: #1290c9;
}
.add_item.on .add_des_txt,
.add_item.on .add_des_title {
    color: #ffffff;
}
.add_img {
    height: 42px;
}
.add_img img {
    width: 38px;
    height: 42px;
    margin-right: 9px;
}
.add_des_title {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 20px;
}
.add_des_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}
.btn_return,
.btn_create {
    width: 180px;
    height: 40px;
    line-height: 40px;
    background: #e7eaed;
    border-radius: 12px;
    font-size: 16px;
    color: #022955;
}
.btn_create {
    width: 180px;
}
.btn_return {
    width: 140px;
}
.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}
.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}
.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}
.btn_comp_box {
    text-align: right;
}
.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}

.table_box /deep/ .el-table .cell {
    padding-right: 0px;
}
.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}
.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}
.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}
.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
}
.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}
.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}
.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}
.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}
.comp_search .coll_search_btn {
    top: 7px;
}

.tree_table_box {
    position: relative;
}
.table_add {
    width: 140px;
    display: flex;
    align-items: center;
}
.table_add img {
    width: 14px;
    margin-left: 4px;
}
.tbl_search_box {
    position: absolute;
    left: 26px;
}
.tbl_search {
    position: relative;
    width: 300px;
    height: 40px;
    z-index: 9;
}
.tbl_search input {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search img {
    position: absolute;
    width: 18px;
    right: 20px;
    top: 12px;
}
/* .table_box /deep/ .el-table .cell{
    padding-left: px !important;
} */
.table_box /deep/.el-table th > .cell:first-child {
    /* padding-left: 15px !important; */
}

.table_box /deep/ .el-table .cell {
    /* padding-left: 24px !important; */
}
.table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 34px !important;
}
.tree_table_box /deep/ .el-table thead .company_link > .cell {
    padding-left: 47px !important;
}
.condition_lab {
    display: flex;
    flex-wrap: wrap;
}
.condition_labb {
    float: left;
    max-width: 888px;
}
.cas_loc_box .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 160px;
    /* padding-top: 10px; */
    font-size: 14px !important;
}
.cas_loc_box .casca_count {
    width: 40px;
}

.condition_labb /deep/ .el-tag {
    background-color: #ffffff;
    border-color: #e7eaed;
    height: 40px;
    line-height: 20px;
    padding: 10px;
    color: #022955;
    border-radius: 12px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}
.condition_labb /deep/ .el-tag .el-tag__close {
    color: #f56b6b;
    font-weight: 600;
}
.condition_labb /deep/ .el-tag .el-tag__close:hover {
    color: #f56b6b;
    background-color: #fff;
}
.indystry .casca_lab {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    width: 164px;
    /* width: 100% !important; */
    /* padding-top: 10px; */
    font-size: 14px !important;
}

.coll_child_num2 {
    float: right;
    /* width: 55px; */
    font-size: 14px;
    color: #8497ab;
    margin-left: 5px;
    text-align: end;
    margin-top: 10px;
}
.tbl_search /deep/ .el-input .el-input__inner {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
    border-radius: 8px;
    border: 1px solid #1290c9;
    padding: 0 40px 0 20px;
}
.tbl_search .el-input {
    height: 100%;
}
.tbl_search /deep/ .el-input .el-input__suffix {
    right: 45px !important;
}
.hedr-icon-ydong{
    position: absolute;
    left: 215px;
    font-weight: 600;
}
/* .left_menu /deep/ .el-collapse-item__arrow{
display: none;
} */
.left_menu /deep/  .el-collapse-item__header{
position: relative;
}
</style>
