<template>
  <div>
    <!-- add to List -->
    <el-dialog :visible.sync="addDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normal">
      <div class="detail_cont">
        <div class="dialog_detail_title">Add to My List</div>
        <div class="add_list">
          <el-checkbox-group v-model="radioGroupName" @change="changhCkCollection">
            <el-checkbox-button v-for="item in addList" :label="item.id" :key="item.id">
              <div class="add_img"><img src="../assets/img/search/add_img1.png" /></div>
              <div class="add_des">
                <div class="add_des_title line_1">{{ item.groupName }}</div>
                <div class="add_des_txt">{{ item.companyNum }} Collections</div>
              </div>
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="btn_box flex_center_between_box">
          <button class="btn_export" @click="submitGroupName">Done</button>
          <button class="btn_create" @click="createNew">Create a new group</button>
        </div>
      </div>
    </el-dialog>

    <!-- create to List -->
    <el-dialog :visible.sync="createDialog" center :close-on-click-modal="false" custom-class="dialog_normal">
      <div class="detail_cont">
        <div class="dialog_detail_title">Add to My List</div>
        <div class="create_item">
          <div class="create_item_lab">
            <span>Name</span>
          </div>
          <div>
            <input class="create_inpt" v-model="groupName" type="text" placeholder="Please enter group name" />
          </div>
        </div>
        <div class="btn_box flex_center_between_box">
          <button class="btn_export" @click="submitCreateNew()">Done</button>
          <button
            class="btn_return"
            @click="
              createDialog = false
              addDialog = true
            "
          >
            return
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addGroup, collectionSave,selectGroup,delCollection } from '@/api/searchApi.js'
export default {
  props: {
    addList: {
      type: Array,
    },
    addPop: {
      type: Boolean,
      default: false,
    },
    collecObj: {
      type: Object,
    },
    fromPage: {
      type: String,
    },
  },
  data() {
    return {
      List:[],
      createDialog: false, //新增分组弹框
      createNewName: '',
      groupName: '',
      radioGroupName:[],//this.collecObj.collectionIdList,
      collec:'',//this.collecObj.isFavorite,
      checkList:[],
    }
  },
  computed: {
    addDialog: {
      get() {
        return this.addPop
      },
      set(newValue) {
        this.$emit('update:addPop', newValue)
      },
    },
  },
  watch: {
    addPop: {
        handler(newVal, oldVal) {
          let that=this
          // if (JSON.stringify(newVal) != "{}") {
            if (newVal) {
              //that.radioGroupName=[]
              this.collec=this.collecObj.isFavorite
              let listId=this.collecObj.collectionIdList?this.collecObj.collectionIdList:''
              let arr=[]
              if(listId.length>0) {
                listId.forEach(function(item,index){
                  arr.push(item.groupId)
                })
              }
              that.radioGroupName=arr
          }
        },
        deep: true  //默认值是 false，代表是否深度监听
    },
  },
  mounted(){
    // this.getAddToList()
  },
  methods: {
    //添加列表
    async getAddToList() {
      await selectGroup().then(res => {
          if (res && res.code) {
              this.$emit('update:addList', res.data.rows)
              // this.addList=res.data.rows
          }
      });
    },
    changhCkCollection(value){
      console.log('changhCkCollection--',value);
    },
    //新增分组
    async createNew() {
      this.addDialog = false
      this.createDialog = true
    },
    async submitCreateNew() {
      if (this.groupName) {
        let params = {
          groupName: this.groupName,
        }
        await addGroup(params).then((res) => {
          if (res && res.code) {
            this.$message({
                message: res.msg,
                center: true,
                type: 'success',
              })
            this.getAddToList()
            this.addDialog = true
            this.createDialog = false
          }else{
            this.$message({
                message: res.msg,
                center: true,
                type: 'error',
              })
          }
        })
      }
    },
    //添加收藏
    async submitGroupName() {
      let collList= this.collecObj.collectionIdList?this.collecObj.collectionIdList:[]
      let newArr = [];
      let delArr=[]
      let defArr = [];

        let collectionCompany = ''
        let crefoNo = ''
        let countryCodeEn = ''
        if (this.fromPage == 'company') {
          collectionCompany = this.collecObj.company_name_en
          crefoNo = this.collecObj.aaa_id
          countryCodeEn = this.collecObj.company_country
        } else {
          collectionCompany = this.collecObj.pnameEn
          crefoNo = this.collecObj.pid
          countryCodeEn = this.collecObj.countryCode
        }
        let idList=this.radioGroupName
        
        //删除原有的收藏
        collList.filter((item) => {
          if(!idList.includes(item.groupId)){
            delArr.push(item.id)
          }
          else{
            defArr.push(item.id)
          }
        })
        if(delArr.length>0){
          await delCollection(delArr).then((res) => {
            if (res && res.code) {
              this.addDialog = false
              this.createDialog = false
              if(defArr.length>0){
                this.collec=true
              }
              else{
                this.collec=false
              }
              this.$message({
                message: res.msg,
                center: true,
                type: 'success',
              })
               this.$emit('getCollData')
              this.$emit('childCloseDialog', false,this.collec)
            }
          })
        }
        //添加到新的收藏
        newArr = idList.filter(itemA => {
          return collList.every(itemB => {
            return itemB.groupId !== itemA
          })
        })
        
        newArr.forEach(async id => {
          const params = {
            collectionCompany: collectionCompany,
            crefoNo: crefoNo,
            groupId: id,
            countryCodeEn: countryCodeEn,
          }
          await collectionSave(params).then((res) => {
            if (res && res.code) {
              this.addDialog = false
              this.createDialog = false
              this.collec=true
              this.$message({
                message: res.msg,
                center: true,
                type: 'success',
              })
              this.$emit('getCollData')
            //    selectGroup().then(res => {
            //     if (res && res.code) {
            //         this.addList = res.data.rows;
            //     }
            // });
              this.$emit('childCloseDialog', false,this.collec)
            }
            else{
              this.$message({
                message: res.msg,
                center: true,
                type: 'error',
              })
              // this.collec=false
              this.$emit('childCloseDialog', false,this.collec)
            }
            
          })
        });
      
    },
    //关闭add弹窗
    closeDialog() {
      this.$emit('childCloseDialog', false,this.collec)
      this.groupName = ''
      this.radioGroupName = ''
    },
  },
}
</script>
<style scoped>
.add_list {
  margin-bottom: 20px;
}
.add_list /deep/ .el-checkbox-button__inner {
  display: flex;
  height: 62px;
  padding: 10px 9px 8px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e7eaed;
}
.add_list /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background: #1290c9;
  color: #ffffff;
  box-shadow: none;
}

.add_list /deep/ .el-checkbox-button__inner:hover {
  background: #e7eaed;
}

.add_list /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner .add_des_txt,
.add_list /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner .add_des_title,
.is-checked .add_des_txt,
.is-checked .add_des_title {
  color: #ffffff;
}

.add_img {
  height: 42px;
  flex: none;
}

.add_img img {
  width: 38px;
  height: 42px;
  margin-right: 9px;
}

.add_des_title {
  width: 370px;
  font-size: 16px;
  font-family: 'Arial Bold';
  color: #022955;
  line-height: 20px;
  text-align: left;
}

.add_des_txt {
  font-size: 12px;
  color: #8497ab;
  line-height: 16px;
  text-align: left;
}

.btn_return,
.btn_create {
  width: 180px;
  height: 40px;
  line-height: 40px;
  background: #e7eaed;
  border-radius: 12px;
  font-size: 16px;
  color: #022955;
}

.btn_create {
  width: 180px;
}

.btn_return {
  width: 140px;
}

.create_item_lab {
  font-size: 16px;
  font-family: 'Arial Bold';
  color: #022955;
  margin-bottom: 8px;
}

.create_item_num {
  font-size: 14px;
  font-family: 'Arial Bold';
  color: #8497ab;
}

.create_inpt {
  width: 100%;
  height: 46px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e7eaed;
  font-size: 16px;
  padding: 13px 20px;
  margin-bottom: 20px;
}
.line_1{
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
}
@media  (max-width: 821px) {
     .add_des_title {
    width: 300px !important;
    } 
}
</style>